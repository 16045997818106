<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="licenseData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No license found with this license id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'licences-dashboard' }"
        >
          license List
        </b-link>
        for other licenses.
      </div>
    </b-alert>

    <b-row
      v-if="licenseData"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h1 class="text-primary">
                    {{ licenseData.business_name ? licenseData.business_name : licenseData.sacco_name }}
                  </h1>
                </div>
                <h4 class="text-primary">
                  {{ licenseData.business_type ? licenseData.business_type : licenseData.car_license_plate_number }}
                </h4>
                <h5
                  v-if="licenseData.county"
                  class="text-primary"
                >
                  <strong>County: </strong>{{ licenseData.county.name }}
                </h5>
                <h6
                  v-if="licenseData.street_name"
                  class="text-primary"
                >
                  <strong>Business Street Name: </strong>{{ licenseData.street_name }}
                </h6>
                <h6
                  v-if="licenseData.town"
                  class="text-primary"
                >
                  <strong>Business Town: </strong>{{ licenseData.town }}
                </h6>
                <h6
                  v-if="licenseData.building_name"
                  class="text-primary"
                >
                  <strong>Building: </strong>{{ licenseData.building_name }}
                </h6>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h1 class="invoice-title">
                  License ID:
                  <span class="invoice-number">#{{ licenseData.license_id }}</span>
                </h1>
                <div class="invoice-date-wrapper justify-content-end">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{ moment(licenseData.updated_at).format("dddd, MMMM Do YYYY") }}
                  </p>
                </div>
                <div class="invoice-date-wrapper justify-content-end">
                  <p class="invoice-date-title">
                    Valid Until:
                  </p>
                  <p class="invoice-date">
                    {{ moment(licenseData.valid_until).format("dddd, MMMM Do YYYY") }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-end">
      <b-card v-if="status === 'expired'">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="secondary"
          class="mb-75"
          block
          @click="renew"
        >
          Renew
        </b-button>
      </b-card>
      <b-card
        v-if="licenseType === 'business'"
        class="download-btn-card"
      >
        <!-- Button: Download -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          class="mb-75"
          block
          @click="downloadLicense"
        >
          Download
        </b-button>
        <!-- Button: Edit -->
        <b-button
          v-if="licenseData"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="danger"
          class="25"
          block
          :to="{ name: 'licence-edit', params: { id: licenseData.license_id }}"
        >
          Edit
        </b-button>
      </b-card>
      <b-card
        v-else
        class="download-btn-card"
      >
        <!-- Button: Download -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          class="mb-75"
          block
          @click="downloadPsvLicense"
        >
          Download
        </b-button>
      </b-card>
    </div>
    <b-card v-if="licenseData">
      <b-card-title title-tag="h2">Invoices</b-card-title>
      <b-card-body>
        <b-table
          :dark="true"
          responsive
          :items="licenseData.invoice"
          :fields="['invoice_id', 'status', 'issued_on', 'Action']"
        >
          <template #cell(invoice_id)="data">
            <b-card-text class="font-weight-bold mb-25">
              {{ data.item.invoice_id }}
            </b-card-text>
          </template>
          <template #cell(status)="data">
            <b-badge :variant="resolveInvoiceStatusVariantAndIcon(data.item.status)">
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(issued_on)="data">
            <span class="text-nowrap">
              {{ moment(data.item.created_at).format("Do MMMM YYYY") }}
            </span>
          </template>
          <template #cell(Action)="data">
            <b-button variant="primary" size="sm" :to="{ name: 'invoice-view', params: { id: data.item.invoice_id } }">View</b-button>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BButton,
  BAlert,
  BLink,
  BTable,
  BBadge,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import licenceStoreModule from './licenceStoreModule'
import useLicenseList from './useLicencesList'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BButton,
    BAlert,
    BLink,
    BTable,
    BBadge,
  },
  setup() {
    const licenseData = ref(null)
    const licenseType = ref('business')
    const status = ref('valid')
    // Use toast
    const toast = useToast()

    const LICENSE_APP_STORE_MODULE_NAME = 'licenses'

    // Register module
    if (!store.hasModule(LICENSE_APP_STORE_MODULE_NAME)) store.registerModule(LICENSE_APP_STORE_MODULE_NAME, licenceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LICENSE_APP_STORE_MODULE_NAME)) store.unregisterModule(LICENSE_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('licenses/fetchLicense', {
        id: router.currentRoute.params.id,
      })
      .then(response => {
        licenseData.value = response.data.data
        licenseData.value.invoice = licenseData.value.invoice.reverse()
        const d1 = new Date()
        const d2 = new Date(licenseData.value.valid_until)
        if (d1 > d2) {
          status.value = 'expired'
        }
        if (licenseData.value.type === 'psv') {
          licenseType.value = 'psv'
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          licenseData.value = undefined
        }
      })

    const renew = () => {
      store.dispatch('licenses/renewLicense', licenseData.value.license_id)
        .then(response => {
          router.push({ name: 'invoice-view', params: { id: response.data.data } })
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const downloadLicense = () => {
      store
        .dispatch('licenses/downloadLicense', licenseData.value.license_id)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute(
            'download',
            `${licenseData.value.license_id}.pdf`,
          )
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error accessing file. Please try again.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const downloadPsvLicense = () => {
      store
        .dispatch('licenses/downloadPsvLicense', licenseData.value.license_id)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute(
            'download',
            `${licenseData.value.license_id}.pdf`,
          )
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error accessing file. Please try again.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const { resolveInvoiceStatusVariantAndIcon } = useLicenseList()

    return {
      licenseData,
      licenseType,
      downloadLicense,
      downloadPsvLicense,
      status,
      renew,
      resolveInvoiceStatusVariantAndIcon,
    }
  },
}
</script>

<style scoped>
.download-btn-card {
  width: 25%;
}
@media only screen and (max-width: 992px) {
  .download-btn-card {
    width: 50%
  }
}
@media only screen and (max-width: 768px) {
  .download-btn-card {
    width: 100%
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
